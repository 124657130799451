import {Injectable, signal} from '@angular/core';
import {ApiService} from "@core/services/api.service";
import {
  AuthenticationInitialChangePasswordRequest,
  AuthenticationRequest,
  AuthenticationResponse
} from "@core/models/authentication.model";
import {firstValueFrom} from "rxjs";
import {CookieService} from "ngx-cookie-service";
import {Router} from "@angular/router";
import {AuthenticationEndPoint} from "@core/const";
import {I18nService} from "@core/services/i18n/i18n.service";
import {PermissionService} from "@core/services/permission.service";
import {SignalService} from "@core/services/signal.service";
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  shouldChangePasswordSignal = signal<boolean>(false);
  isAuthenticated = signal(false)

  constructor(private apiService: ApiService,
              private i18n: I18nService,
              private cookieService: CookieService,
              private router: Router,
              private signalService: SignalService,
              private permissionService: PermissionService) {
  }

  async login(request: AuthenticationRequest): Promise<void> {
    const response = await firstValueFrom(this.apiService.post<AuthenticationRequest, AuthenticationResponse>(AuthenticationEndPoint.LOGIN, request));
    this.permissionService.saveAuthorities(response.authorities);
    this.permissionService.saveRole(response.role);
    this.signalService.branchMapPreferenceSignal.set(response.companyBranch);
    this.signalService.profileImageSignal.set(`${environment.serverApiUrlImages}employees/${response.profileImage}`)
    this.i18n.switchLanguages(response.language);
    this.signalService.employeeName.set(response.name);

    if (!response.hasChangedPassword) {
      this.shouldChangePasswordSignal.set(true);
      return;
    }
    await this.router.navigate(["/home"]);
  }

  async changePassword(request: AuthenticationInitialChangePasswordRequest): Promise<void> {
    await firstValueFrom(this.apiService.post<AuthenticationInitialChangePasswordRequest, void>(AuthenticationEndPoint.CHANGE_PASSWORD, request));
    await this.router.navigate(["/home"]);
    this.shouldChangePasswordSignal.set(false);
  }

  async refresh() {
    try {
      await firstValueFrom(this.apiService.get<void>(AuthenticationEndPoint.REFRESH, {}, "body", false));
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async logout() {
    await firstValueFrom(this.apiService.get<void>(AuthenticationEndPoint.LOGOUT));
    await this.router.navigate(["/login"]);
  }

  isAccessTokenValid(): boolean {
    return this.cookieService.check("accessToken");
  }

  async isUserAuthenticated() {
    try {
      await firstValueFrom(this.apiService.get<void>(AuthenticationEndPoint.VALIDATE, {}, "body", false));
      this.isAuthenticated.set(true)
      return true;
    } catch (error) {
      this.isAuthenticated.set(false)
      return false;
    }
  }
}
